<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="12">
        <FormSection title="Are you:" darkTitle>
          <v-radio-group
            @change="
              handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_COACH_PLAYER_TYPE, arguments[0])
            "
            :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_COACH_PLAYER_TYPE]"
            :rules="['required']"
            row
          >
            <v-radio value="COACH" input-value="SINGLE" :label="$t('fields.coach')"></v-radio>
            <v-radio value="PLAYER" input-value="MARRIED" :label="$t('fields.player')"></v-radio>
          </v-radio-group>
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME]"
          :rules="['required']"
          :label="$t('fields.injuredPersonFirstName')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME]"
          :rules="['required']"
          :label="$t('fields.injuredPersonLastName')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <PhDatepicker
          fullWidth
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_DOB, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_DOB]"
          :hide-details="false"
          :label="$t('fields.injuredPersondDOB')"
          required
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_GENDER, arguments[0])"
          :items="selectOptions.genders"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_GENDER]"
          :rules="['required']"
          :label="$t('fields.injuredPersondGender')"
        />
      </v-col>
    </v-row>
    <v-row v-if="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_COACH_PLAYER_TYPE] === 'COACH'">
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_PARENTS_PHONE, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_PARENTS_PHONE]"
          :rules="['phone']"
          type="tel"
          label="Parent/Guardian Phone"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm } from '@/mixins';
import { genders, organizationRoles } from '@/utils/options';
import { getClaimantInfoFormProps } from '@/utils/helpers/app/claimsUsasa';
import PhDatepicker from '@/components/pickers/PhDatepicker';
import FormSection from '@/components/FormSection';

export default {
  name: 'ClaimantInfo',
  mixins: [syncForm],
  components: {
    PhDatepicker,
    FormSection,
  },
  props: {
    ...getClaimantInfoFormProps(),
  },
  data() {
    return {
      selectOptions: {
        genders,
        organizationRoles,
      },
    };
  },
};
</script>
