<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(PROVIDER_FIELD_ALIASES.NAME, arguments[0])"
          :value="providerInformation[PROVIDER_FIELD_ALIASES.NAME]"
          :rules="['required']"
          :label="$t('fields.providerName')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(PROVIDER_FIELD_ALIASES.ADDRESS, arguments[0])"
          :value="providerInformation[PROVIDER_FIELD_ALIASES.ADDRESS]"
          :rules="['required']"
          :label="$t('fields.providerAddress')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(PROVIDER_FIELD_ALIASES.EMAIL, arguments[0])"
          :value="providerInformation[PROVIDER_FIELD_ALIASES.EMAIL]"
          type="email"
          :rules="['required', 'email']"
          :label="$t('fields.providerEmail')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(PROVIDER_FIELD_ALIASES.PHONE_NUMBER, arguments[0])"
          :value="providerInformation[PROVIDER_FIELD_ALIASES.PHONE_NUMBER]"
          :rules="['phone', 'required']"
          type="tel"
          :label="$t('fields.providerPhoneNumber')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm } from '@/mixins';
import { getProviderInfoFormProps } from '@/utils/helpers/app/claimsUsasa';

export default {
  name: 'ProviderInfo',
  mixins: [syncForm],
  props: {
    ...getProviderInfoFormProps(),
  },
  methods: {
    handleFieldInput(field, value) {
      return this.handleNonSyncFieldInput(`providerInformation.${field}`, value);
    },
  },
};
</script>
